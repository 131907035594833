<script setup lang="ts">
import { LogoHorizontalIcon } from '@glow/shared/components/icons';
import {
  TelegramIcon,
  VkIcon,
  YoutubeIcon,
  TiktokIcon,
} from '@glow/shared/components/icons/social';
import {
  policies,
  type Policy,
  PolicyModal,
} from '@glow/shared/components/policies';
import type { LinkWithTitle } from '@glow/shared/utils/types';
import AppLayoutFooterAppBlock from './AppLayoutFooterAppBlock.vue';
import AppLayoutFooterLinkGroup from './AppLayoutFooterLinkGroup.vue';
import { socialLinks } from '@glow/shared/lib/constants';
import { getUrl } from '@glow/shared/lib/routes';
import { specializations } from '@glow/shared/features/specializations';

const footerPolicies: Policy[] = [
  'companyInfo',
  'userTerms',
  'privacyPolicy',
  'cookiePolicy',
  'license',
  'contractOffer',
];
const openedPolicy = ref<Policy>();

function getSpecializationUrl(slug: string) {
  return getUrl('partnerCatalog', {
    city: 'moscow',
    specialization: slug,
    type: 'master',
  });
}

const specializationLinks = computed<LinkWithTitle[]>(() =>
  specializations.map((specialization) => ({
    link: getSpecializationUrl(specialization.slug)!,
    title: specialization.name,
  }))
);
const additionalLinks: LinkWithTitle[] = [
  {
    title: 'Блог',
    link: getUrl('blogPosts'),
  },
  {
    title: 'Больше о нас',
    link: getUrl('clientLanding'),
  },
  {
    title: 'Страница для мастеров',
    link: getUrl('masterLanding'),
  },
  {
    title: 'Помощь в регистрации для мастеров',
    link: getUrl('masterRegisterHelpLanding'),
  },
];

type SocialItem = {
  link: string;
  icon: Component;
};
const socialItems: SocialItem[] = [
  {
    link: socialLinks.vk,
    icon: VkIcon,
  },
  {
    link: socialLinks.telegramClientChat,
    icon: TelegramIcon,
  },
  {
    link: socialLinks.youtube,
    icon: YoutubeIcon,
  },
  {
    link: socialLinks.tiktok,
    icon: TiktokIcon,
  },
];
</script>

<template>
  <div class="footer">
    <div class="footer__about">
      <LogoHorizontalIcon class="footer__logo" />
      <div class="footer__social-links">
        <AppButton
          v-for="(item, i) in socialItems"
          :key="i"
          class="footer__social-link"
          variant="link"
          target="_blank"
          :to="item.link"
          :icon-start="item.icon"
        />
      </div>
      <div class="footer__policies d-none d-md-block">
        <div
          v-for="policy in footerPolicies"
          :key="policy"
          class="footer__policy-link"
          @click="openedPolicy = policy"
        >
          {{ policies[policy].title }}
        </div>
      </div>
    </div>
    <div class="footer__links">
      <AppLayoutFooterLinkGroup title="Каталог" :links="specializationLinks" />
      <AppLayoutFooterLinkGroup
        title="Дополнительно"
        :links="additionalLinks"
      />
    </div>
    <div class="footer__app d-none d-md-block">
      <AppLayoutFooterAppBlock />
    </div>
    <div class="footer__policies d-block d-md-none">
      <div
        v-for="policy in footerPolicies"
        :key="policy"
        class="footer__policy-link"
        @click="openedPolicy = policy"
      >
        {{ policies[policy].title }}
      </div>
    </div>
    <PolicyModal :policy="openedPolicy" @close="openedPolicy = undefined" />
  </div>
</template>

<style scoped lang="scss">
.footer {
  display: flex;
  flex-wrap: wrap;
  gap: 64px;

  @include media-breakpoint-down(sm) {
    gap: var(--app-spacing-6);
  }

  &__about {
    display: flex;
    flex-direction: column;
    gap: 52px;
  }

  &__logo {
    width: 115px;
    height: auto;

    @include media-breakpoint-down(md) {
      width: 193px;
    }
  }

  &__social-links {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--app-spacing-4);
  }

  &__social-link {
    font-size: 20px;
  }

  &__policies {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__policy-link {
    color: var(--app-color-black-secondary);
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
  }

  &__links {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
  }

  &__app {
    width: 228px;
  }
}
</style>
